import { createI18n } from "vue-i18n";
import nl from './nl.json'
import fr from './fr.json'
import en from './en.json'

export default createI18n({
    locale: import.meta.env.VITE_DEFAULT_LOCALE,
    fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
    legacy: false,
    globalInjection: true,
    messages: {
        nl,
        fr,
        en
    }
  })