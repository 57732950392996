import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from "vue-i18n";

export const useBreadCrumbStore = defineStore('breadCrumbStore', () => {
    const routes = ref([]);

    const i18n = useI18n();
    let routesToTitles = new Map();
    routesToTitles.set('/installations', i18n.t('breadCrumbNav.installations'));
    routesToTitles.set('/installationoverview/[installation_id]', i18n.t('breadCrumbNav.installations_overview'));
    routesToTitles.set('/invoicedetail/list/[id]', i18n.t('breadCrumbNav.invoice_list'));
    routesToTitles.set('/invoicedetail/[id]', i18n.t('breadCrumbNav.invoice'));

    function setRoutes(_routes) {
        routes.value.splice(0, routes.value.length);
        for(let route of _routes) {
            if(!route.title){
                route.title = routesToTitles.get(route.name);
                route.to = {
                    name: route.name,
                    params: route.params
                };
                route.link = true;
                route.exact = true;
                route.disabled = false;
            }
            routes.value.push(route);
        }
    }

    function setCurrentInstallation(installation_id) {
        
    }
  
    return { 
        routes,
        setRoutes,
    }
})