import { getApiRoot } from '@/helpers/api'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage'
import { getSubdomain } from '@/helpers/util';
import axios from "axios"
import i18n from '@i18n';
import VueCookies from 'vue-cookies'

const { t } = i18n.global; 


export const OfferTypes = {
    SolarPanels: 1,
    SolarBoilers: 2,
    HeatPumps: 3,
    HeatPumpBoiler: 4,
    Other: 5,
    Batteries: 6,
    ChargingStations: 10,
    Control: 15
}

export const OfferTypesTextKeys = {
    1: "solarPanels",
    2: "solarBoilers",
    3: "heatpumps",
    4: "heatpumpBoilers",
    5: "other",
    6: "batteries",
    10: "chargingStations",
    15: "control"
}

const OfferStatuses = {
    New: 1,
    Sent_Initial_Offer: 2,
    Make_Dedicated_Offer: 3,
    Sent_Dedicated_Offer: 4,
    Visit: 5,
    Sent_Final_Offer: 6,
    Completed: 7,
    Canceled: 8,
    Make_Final_Offer: 9,
    Not_Enough_Information: 11
}



export const useOfferStore = defineStore('offerStore', () => {
    const offer = ref(null);
    const versions = ref(null);
    const customerSignatureCategoryID = 63;
    const StatusExpirationDays = 10;

    async function getOfferByID(id, versionNumber){
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.get(
                getApiRoot() + '/sales/offers/' + id + '/?lang=nl-BE&relatedData=articles,offerVersions', 
                { headers }
            )
            if(response.data.errors.length == 0){
                let result = response.data.data[0];
                if(result.offerVersions && result.offerVersions.length > 0){
                    offer.value = await loadOfferVersion(result, versionNumber);
                }
                else{
                    offer.value = result;
                }
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw error;
        }
    }

    async function loadOfferVersion(offer, versionNumber){
        let offerVersion = offer.offerVersions.filter((versions) => { return versions.VersionNumber == versionNumber; })[0];
        if(!offerVersion) {
            return offer;
        }
        offerVersion.Data = JSON.parse(offerVersion.Data);

        // Replace offer fields
        for(const prop in offerVersion.Data.fields){
            offer[prop] = offerVersion.Data.fields[prop]
        }

        await getArticlesForOfferVersion(offerVersion);
        
        let replacedArticleIDs = [];
        // Replace articles that match version
        offerVersion.Data.inputLists.inputListArticles.rows.forEach(versionArticle => {
            let offerArticleIndex = offer.articles.findIndex(article => article.ID == versionArticle.ArticleID);
            let offerArticle = offer.articles[offerArticleIndex];
            if(offerArticle === undefined){
                offer.articles.push(versionArticle);
                replacedArticleIDs.push(versionArticle.ArticleID);
                return;
            }
            for(const prop in versionArticle){
                offerArticle[prop] = versionArticle[prop];
            }
            replacedArticleIDs.push(versionArticle.ArticleID);
        })

        // Remove articles not in this version
        for(let i = 0; i < offer.articles.length; ++i){
            if(!replacedArticleIDs.includes(offer.articles[i].ID)){
                offer.articles.splice(i, 1);
            }
        }
        return offer;
    }

    async function getOfferVersions(id){
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.get(
                getApiRoot() + '/sales/offers/' + id + '/offerVersions/?lang=nl-BE', 
                { headers }
            )
            if(response.data.errors.length == 0){
                let offerVersions = response.data.data[0].offerVersions;
                await offerVersions.forEach(async (version) => {
                    version.Data = JSON.parse(version.Data);
                    await getArticlesForOfferVersion(version);
                });
                versions.value =  offerVersions;
             }
             else{
                 throw new Error(response.data.errors)
             }
        } catch(error){
            throw error;
        }
    }

    async function getArticlesForOfferVersion(version){
        let articleIDs = [];
        version.Data.inputLists.inputListArticles.rows.forEach(async (article) => {
            articleIDs.push(article.ArticleID);
        })

        if(articleIDs.length === 0) {
            return;
        }
        
        const articlesData = await getArticlesData(articleIDs);
        await version.Data.inputLists.inputListArticles.rows.forEach(async (article) => {
            const articleData = articlesData.filter(articleData =>  {return articleData.ArticleID == article.ArticleID })[0];
            for(const prop in articleData){
                article[prop] = articleData[prop];
            }
        })
    }

    async function getArticlesData(articleIDs){
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.get(
                getApiRoot() + '/management/articles/' + articleIDs.join(',') + '/?lang=nl-BE', 
                { headers }
            )
            if(response.data.errors.length == 0){
                return response.data.data;
             }
             else{
                 throw new Error(response.data.errors)
             }
        } catch(error){
            throw error;
        }
    }

    async function uploadSignature(offerID, base64image){
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.post(
                getApiRoot() + '/offers/' + offerID + '/upload', 
                {
                    fileContent: base64image.data,
                    fileCategoryID: customerSignatureCategoryID,
                    fileExtension: "png",
                    recordTable: "offers",
                    overwrite: true
                },
                { headers }
            )
            if(response.status === 200){
                return true;
            }
            else{
                throw new Error(response.data.message);
            }
        } catch(error){
            throw error;
        }
    }

    async function updateOfferStatus(newStatus, offerID) {
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.patch(
                getApiRoot() + '/offers/' + offerID, 
                {
                    CurrentStatusID: newStatus
                },
                { headers }
            )
            if(response.data.errors.length == 0){
                return true;
             }
             else{
                 throw new Error(response.data.errors)
             }
        } catch(error){
            throw error;
        }
    }

    async function makeOrder(offerID) {
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.get(
                `${getApiRoot()}/sales/offers/${offerID}/action/make_order`,
                { headers }
            )
            if(response.data.errors.length == 0){
                return true;
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw error;
        }
    }

    async function getOfferStatuses() {
        let OfferStatuses = getLocalstorageItem("OfferStatuses");
        if(OfferStatuses !== undefined){
            return OfferStatuses;
        }
        return (await parseStatusResponse())?.OfferStatuses;
    }

    async function getInitialOfferStatus() {
        let InitialOfferStatus = getLocalstorageItem("InitialOfferStatus");
        if(InitialOfferStatus !== undefined) {
            return InitialOfferStatus;
        }
        return (await parseStatusResponse())?.InitialOfferStatus;
    }

    async function getFinalOfferStatus() {
        let FinalOfferStatus = getLocalstorageItem("FinalOfferStatus");
        if(FinalOfferStatus !== undefined) {
            return FinalOfferStatus;
        }
        return (await parseStatusResponse())?.FinalOfferStatus;
    }

    async function parseStatusResponse(){
        try{
            const response = await axios.get(
                `${getApiRoot()}/static/data/config/offerStatuses/offer_status/`, {
                    params: {
                      subDomain: getSubdomain(),
                      lang: "nl-BE"
                    }
                }
            )
            if(response.data.errors.length == 0){
                let OfferStatuses = {};
                let InitialOfferStatus = null;
                let FinalOfferStatus = null;
                const data = response.data.data;
                for(const status of data) {
                    OfferStatuses[status.Status.split(' ').join('_')] = status.Offer_StatusID;
                }
                InitialOfferStatus = data.filter(status => { return status.Initial === 1})[0]?.Offer_StatusID;
                FinalOfferStatus = data.filter(status => { return status.Final === 1})[0]?.Offer_StatusID;
                
                setLocalstorageItem("OfferStatuses", OfferStatuses, StatusExpirationDays);
                setLocalstorageItem("InitialOfferStatus", InitialOfferStatus, StatusExpirationDays);
                setLocalstorageItem("FinalOfferStatus", FinalOfferStatus, StatusExpirationDays);

                return {
                    OfferStatuses: OfferStatuses,
                    InitialOfferStatus: InitialOfferStatus,
                    FinalOfferStatus: FinalOfferStatus
                }
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw error;
        }
        
    }
  
    return { 
        offer, 
        versions,
        getOfferStatuses,
        getInitialOfferStatus,
        getFinalOfferStatus,
        getOfferByID,
        getOfferVersions,
        uploadSignature,
        updateOfferStatus,
        makeOrder
    }
})