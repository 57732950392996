import { getApiRoot } from '@/helpers/api'
import { defineStore } from 'pinia'
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage'
import { getSubdomain } from '@/helpers/util';
import axios from "axios"
import VueCookies from 'vue-cookies'

const ArticleGroupTypes_backup = {
    Panels: 1,
    SolarBoilerCollectors: 2,
    Inverters: 3,
    Optimizers: 4,
    SolarBoilerSystem: 5,
    Heatpump: 6,
    HeatpumpBoiler: 7,
    Guarantees: 8,
    PostalCodeDependant: 9,
    Accesories: 14,
    BatteryInverters: 15,
    BatteryAccesories: 16,
    Batteries: 17,
    Inspections: 18,
    ChargingStations: 25
}

export const useArticleStore = defineStore('articleStore', () => {
    const GroupTypesExpirationDays = 10;

    async function getArticlesData(articleIDs){
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.get(
                getApiRoot() + '/management/articles/' + articleIDs.join(',') + '/?lang=nl-BE', 
                { headers }
            )
            if(response.data.errors.length == 0){
                return response.data.data;
             }
             else{
                 throw new Error(response.data.errors)
             }
        } catch(error){
            throw new Error(error);
        }
    }

    function getArticleByGroupType(articles, groupType) {
        const newline = '</br>';
        if(articles != null){
            if(articles){
                let articleString = "";
                articles.forEach(article => {
                    if(article.ArticleGroup_Type == groupType){
                        articleString += article.Quantity > 1 ? article.Quantity + ' x ' + article.ArticleCode : article.ArticleCode;
                        articleString += newline;
                    }
                })
                return articleString == "" ? "/" : articleString.substring(0, articleString.length - newline.length);
            }
            else{
                return "/";
            }
        }
        else{
            return "/";
        }
    }

    async function getArticleGroupTypes() {
        let ArticleGroupTypes = getLocalstorageItem("ArticleGroupTypes");
        if(ArticleGroupTypes !== undefined) {
            return ArticleGroupTypes;
        }
        try{
            return await parseArticleGroupTypesResponse();
        }catch(error){
            console.log(error);
            return undefined;
        }
    }

    async function parseArticleGroupTypesResponse(){
        const response = await axios.get(
            `${getApiRoot()}/static/data/config/articleGroupsTypes/article_groups_types/`, {
                params: {
                  subDomain: getSubdomain(),
                  lang: "nl-BE"
                }
            }
        )
        if(response.data.errors.length == 0){
            let ArticleGroupTypes = {};
            const data = response.data.data;
            for(const ArticleGroupType of data) {
                ArticleGroupTypes[ArticleGroupType.ArticleGroupTypeName.split(' ').join('_')] = ArticleGroupType.ArticleGroup_TypeID;
            }
            
            setLocalstorageItem("ArticleGroupTypes", ArticleGroupTypes, GroupTypesExpirationDays);

            return ArticleGroupTypes;
        }
        else{
            throw new Error(response.data.errors)
        }
    }

    return {
        getArticlesData,
        getArticleByGroupType,
        getArticleGroupTypes
    }
});