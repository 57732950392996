import { localStorageKeys, getLocalstorageItem } from "@helpers/localStorage";

export function getApiUrl(path){
    const User = getLocalstorageItem(localStorageKeys.user);
    return import.meta.env.VITE_HAPPERP_DOMAIN + ':' + import.meta.env.VITE_HAPPERP_PORT + 
        '/api/' + path + '/' + User._id;
}

export function getApiRoot(){
    return import.meta.env.VITE_HAPPERP_DOMAIN + ':' + import.meta.env.VITE_HAPPERP_PORT + '/api'
}