/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'
import { aliases as defaultAliases } from 'vuetify/iconsets/mdi';
import axios from 'axios';
import { getApiRoot } from '@/helpers/api'
import { getSubdomain, hasSubdomain } from '@/helpers/util';
import dashboard from '@icons/dashboard.vue'
import dashboard_selected from '@icons/dashboard_selected.vue'
import installations from '@icons/installations.vue'
import installations_selected from '@icons/installations_selected.vue'
import installations_selected_sidebar from '@icons/installations_selected_sidebar'
import monitoring from '@icons/monitoring.vue'
import monitoring_selected from '@icons/monitoring_selected.vue'
import aftersale from '@icons/aftersale.vue'
import aftersale_selected from '@icons/aftersale_selected.vue'
import ticketing from '@icons/ticketing.vue'
import ticketing_selected from '@icons/ticketing_selected.vue'
import energy from '@icons/energy.vue'

let colors = {
  primary: '#A0D52D',
  primary100: '#F5FAEA',
  secondary: '204A23',
  base900: '#0E0F0B',
  base800: '#1D2939',
  base700: '#344054',
  base600: '#475467',
  base500: '#667085',
  base400: '#98A2B3',
  base300: '#D0D5DD',
  base200: '#EAECF0',
  base100: '#F2F4F7',
  base50: '#F9FAFB',
  base25: '#FCFCFD',
  base0: '#FFFFFF',
  error900: '#7A271A',
  error800: '#912018',
  error700: '#B42318',
  error600: '#D92D20',
  error500: '#F04438',
  error400: '#F97066',
  error300: '#FDA29B',
  error200: '#FECDCA',
  error100: '#FEE4E2',
  error50: '#FEF3F2',
  error25: '#FFFBFA',
  warning900: '#7A2E0E',
  warning800: '#93370D',
  warning700: '#B54708',
  warning600: '#DC6803',
  warning500: '#F79009',
  warning400: '#FDB022',
  warning300: '#FEC84B',
  warning200: '#FEDF89',
  warning100: '#FEF0C7',
  warning50: '#FFFAEB',
  warning25: '#FFFCF5',
  succes900: '#054F31',
  succes800: '#05603A',
  succes700: '#027A48',
  succes600: '#039855',
  succes500: '#12B76A',
  succes400: '#32B583',
  succes300: '#6CE9A6',
  succes200: '#A6F4C5',
  succes100: '#D1FADF',
  succes50: '#ECFDF3',
  succes25: '#F6FEF9',
};

if(hasSubdomain()){
  try{
    const response = await axios.get(
      getApiRoot() + '/portal/colours', {
        params: {
          subDomain: getSubdomain()
        }
      }
    );
    if(response.status == 200){
      colors = response.data.data;
    }
  }catch(err){
    console.log(err)
  }
}

const lightTheme = {
  dark: false,
  colors: {...colors}
}

const aliases = {
  ...defaultAliases,
  dashboard,
  installations,
  monitoring,
  aftersale,
  ticketing,
  dashboard_selected,
  installations_selected,
  installations_selected_sidebar,
  monitoring_selected,
  aftersale_selected,
  ticketing_selected,
  energy
}


export default createVuetify({
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme
    }
  },
  icons: {
    aliases
  }
})
