<template>
    <v-snackbar v-model="showSnackBar" :color="snackbarColour">
        {{ snackbarText }}
        <template v-slot:actions>
            <v-btn
                icon="mdi-close"
                @click="showSnackBar = false"
            >
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script setup>
import { useSnackbarStore } from '@/store/snackbar';
import { storeToRefs } from 'pinia';

const snackbarStore = useSnackbarStore();
let { 
    snackbarText,
    snackbarColour,
    showSnackBar
} = storeToRefs(snackbarStore)

</script>