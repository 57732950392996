<template>
    <v-card>
      <v-navigation-drawer
        class="bg-deep-purple"
        color="secondary"
        permanent
      >
        <v-list-item class="pt-10 px-5">
            <v-img src="@assets/energreen.svg" width="250" height="60">
            </v-img>
        </v-list-item>

        <v-list class="px-5 pt-10">
            <v-list-item 
                color="primary"
                active-color="primary"
                :prepend-icon="route.name == '/installations' || route.name && route.name.includes('installation') ? '$installations_selected_sidebar' : '$installations'"
                :title="$t('bottomNav.installations')"
                ripple
                rounded="lg"
                @click="navigateTo('installations')"
            ></v-list-item>
        </v-list>

        <template v-slot:append>
            <div class="pa-5">
                <v-list-item 
                    class="pa-5"
                    prepend-icon="mdi-logout"
                    :title="$t('logout')"
                    @click="logout"
                    v-ripple
                ></v-list-item>
            </div>
        </template>
      </v-navigation-drawer>
  </v-card>
</template>

<script setup>
import { useUserStore } from '@/store/user';
import router from '@/router'
import { useRoute } from 'vue-router'

const route = useRoute();

const userStore = useUserStore();
const { logoutUser } = userStore;

function navigateTo(path) {
    router.push({ name: `/${path}`})
}

function logout(){
    logoutUser();
}

</script>

<style scoped>

.noOpacity {
    opacity: 100 !important;
    color: black;
}

</style>