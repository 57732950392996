import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from "axios"
import { getApiUrl } from '@helpers/api'

import { useOfferStore } from '@store/offers'
import { useOrderStore } from '@store/orders'
import { useDeliveryStore } from '@store/deliveries'
import { InvoiceStatuses } from '@store/invoices'
import { InspectionStatuses, FluviusStatuses } from '@store/inspections'
import { 
    localStorageKeys, 
    getLocalstorageItem, 
    setLocalstorageItem,
    removeLocalstorageItem
} from '@helpers/localStorage'
import VueCookies from 'vue-cookies'

export const InstallationStatuses = {
    New: 1,
    Signed: 2,
    Prepaid: 3,
    Planned: 4,
    Paid: 5,
    InspectionPlanned: 6,
    Completed: 7,
}

export const useInstallationStore = defineStore('installationStore', () => {
    const installations = ref([]);
    const offerStore = useOfferStore();
    const orderStore = useOrderStore();
    const deliveryStore = useDeliveryStore();
    let { getFinalOfferStatus } = offerStore;
    let { getFinalOrderStatus } = orderStore;
    let { getFinalDeliveryStatus } = deliveryStore;
    

    async function setInstallationsStatus(installations){
        const FinalOfferStatus = await getFinalOfferStatus();
        const FinalOrderStatus = await getFinalOrderStatus();
        const FinalDeliveryStatus = await getFinalDeliveryStatus();

        installations.forEach((installation, index) => {
            installation.id = index;
            if(installation.OfferID > 0){
                if(installation.OfferStatus == FinalOfferStatus){
                    installation.status = InstallationStatuses.Signed;
                }
                else{
                    installation.status = InstallationStatuses.New;
                }
            }

            if(installation.OrderID > 0) {
                if(installation.OrderStatus == FinalOrderStatus){
                    installation.status = InstallationStatuses.Prepaid;
                }
                else{
                    installation.status = InstallationStatuses.Signed;
                }
            }

            if(installation.Order_DeliveriesID > 0){
                if(installation.DeliveryStatus == FinalDeliveryStatus){
                    installation.status = InstallationStatuses.Planned;
                }
                else{
                    installation.status = InstallationStatuses.Prepaid;
                }
            }

            if(installation.InvoiceID > 0 && installation.DeliveryStatus == FinalDeliveryStatus){
                if(installation.InvoiceStatus == InvoiceStatuses.Paid){
                    installation.status = InstallationStatuses.Paid;
                }
                else{
                    installation.status = InstallationStatuses.Planned;
                }
            }
            
            if((installation.InspectionHistoryID > 0 || installation.OrderInspectionJobID > 0 || installation.InvoiceInspectionJobID > 0)
                && installation.InvoiceStatus == InvoiceStatuses.Paid
                ){
                    installation.inspectionOnOrder = installation.OrderInspectionJobID > 0;
                    installation.inspectionOnInvoice = installation.InvoiceInspectionJobID > 0;
                    installation.inspectionOnHistory = installation.InspectionHistoryID > 0;

                if(installation.inspectionOnHistory && installation.InspectionStatus == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.Completed;
                }
                else if(installation.inspectionOnInvoice && installation.InvoiceInspectionApproved == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.Completed;
                }
                else if(installation.inspectionOnOrder && installation.OrderInspectionApproved == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.Completed;
                }
                else{
                    installation.status = InstallationStatuses.InspectionPlanned;
                }

                if(installation.FluviusStatus == FluviusStatuses.Requested) {
                    installation.status = InstallationStatuses.InspectionPlanned;
                }
            }
        });
        return installations;
    }

    async function getInstallations() {
        const localStorageInstallations = getLocalstorageItem(localStorageKeys.installations);

        if(localStorageInstallations){
            installations.value = localStorageInstallations;
        }
        else{
            try{
                const headers = { Authorization: `${VueCookies.get('token')}` };
                const response = await axios.get(
                    getApiUrl('management/customers') + '/installations/?lang=nl-BE', 
                    { headers }
                )
                if(response.data.errors.length == 0){
                    let reponseInstallations = await setInstallationsStatus(response.data.data[0].installations);
                    installations.value = reponseInstallations;
                    setLocalstorageItem(localStorageKeys.installations, reponseInstallations);
                }
                else{
                    throw new Error(response.data.errors)
                }
            } catch(error){
                throw new Error(error);
            }
        }
    }

    async function refreshInstallations(){
        removeLocalstorageItem(localStorageKeys.installations);
        await getInstallations();
    }

    async function getInstallationByID(id){
        await getInstallations();
        return installations.value.find((installation) => installation.id == id);
    }

    async function getInstallationOffer(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            OfferID: installation.OfferID == undefined ? NaN : installation.OfferID,
            CurrentStatusID: installation.OfferStatus,
            OfferVersionNumbers: installation.OfferVersionNumbers,
            OfferActiveVersionNumber: installation.VersionNumber,
            Offer_TypeID: installation.Offer_TypeID,
        }
    }

    async function getInstallationOrder(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            OrderID: installation.OrderID == undefined ? NaN : installation.OrderID,
            CurrentStatusID: installation.OrderStatus,
            PrepaymentID: installation.PrepaymentInvoiceID,
            PrepaymentStatus: installation.PrepaymentInvoiceStatus
        }
    }

    async function getInstallationDelivery(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            Order_DeliveriesID: installation.Order_DeliveriesID == undefined ? NaN : installation.Order_DeliveriesID,
            CurrentStatusID: installation.DeliveryStatus
        }
    }

    async function getInstallationInvoice(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            InvoiceID: installation.InvoiceID == undefined ? NaN : installation.InvoiceID,
            CurrentStatusID: installation.InvoiceStatus
        }
    }

    async function getInstallationInspection(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);

        const inspectionID = installation.inspectionOnHistory ? installation.InspectionHistoryID : 
                                installation.inspectionOnInvoice ? installation.InvoiceID :
                                    installation.inspectionOnOrder ? installation.OrderID : NaN;
        
        const inspectionSource = installation.inspectionOnHistory ? 'inspection' : 
                                    installation.inspectionOnInvoice ? 'invoice' :
                                        installation.inspectionOnOrder ? 'order' : '';

            
        let inspectionStatus = InspectionStatuses.Requested; 

        if(installation.inspectionOnHistory) {
            inspectionStatus = installation.InspectionStatus;
        }
        else if(installation.inspectionOnInvoice && installation.InvoiceInspectionApproved == InspectionStatuses.Approved) {
            inspectionStatus = InspectionStatuses.Approved;
        }
        else if(installation.inspectionOnOrder && installation.OrderInspectionApproved == InspectionStatuses.Approved) {
            inspectionStatus = InspectionStatuses.Approved;
        }

        const fluviusStatus = installation.FluviusStatus;

        return {
            InspectionID: inspectionID,
            InspectionSource: inspectionSource,
            InspectionStatus: inspectionStatus,
            FluviusStatus: fluviusStatus,
        }
    }
  
    return { 
        installations, 
        refreshInstallations,
        getInstallations,
        getInstallationByID, 
        getInstallationOffer, 
        getInstallationOrder,
        getInstallationDelivery, 
        getInstallationInvoice,
        getInstallationInspection
    }
})