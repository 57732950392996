import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getApiRoot } from '@helpers/api'
import { getSubdomain } from '@/helpers/util'
import { 
    localStorageKeys,
    getLocalstorageItem,
    setLocalstorageItem,
    removeLocalstorageItem
 } from '@helpers/localStorage'
import axios from "axios"
import router from '@/router';
import VueCookies from 'vue-cookies'
import {useI18n} from "vue-i18n";

export const useUserStore = defineStore('userStore', () => {
    const userLoggedIn = ref(false);
    const i18n = useI18n();

    async function loginUser(email, password) {
        try{
            const response = await axios.post(getApiRoot() + '/customers/login', {
                email: email,
                password: password,
                subDomain: getSubdomain()
            }, {withCredentials: true})
            if(response.data.errors.length == 0){
                VueCookies.set('token', response.data.data.token, "24h");
                setLocalstorageItem(localStorageKeys.user, response.data.data, 1/3);
                i18n.locale.value = response.data.data.languageCode.toLowerCase();
                userLoggedIn.value = true;
            }
            else{
                throw new Error(response.data.errors)
            }
        }
        catch(error){
            throw error;
        }
    }

    async function resetPassword(queryData){
        const response = await axios.post(
            `${getApiRoot()}/customers/resetpassword`,
            {
                request: queryData.request
            }
        )
        if(response.data.errors.length == 0){
            return true;
        }
        else{
            return false;
        }
    }

    async function requestPasswordReset(email) {
        const response = await axios.post(
            `${getApiRoot()}/customers/resetpassword`, 
            {
                email: email,
                subDomain: getSubdomain()
            }
        )
        if(response.data.errors.length == 0){
            return true;
        }
        else{
            throw new Error(response.data.errors)
        }
    }

    function logoutUser(){
        removeLocalstorageItem(localStorageKeys.user);
        removeLocalstorageItem(localStorageKeys.installations);
        VueCookies.remove('token');
        userLoggedIn.value = false;
        router.push({path: '/login'});
    }
  
    return { 
        userLoggedIn,
        loginUser, 
        logoutUser,
        requestPasswordReset,
        resetPassword
    }
})