import { defineStore } from 'pinia'
import { setSessionstorageItem, getSessionStorageItem } from '@/helpers/sessionStorage';

class RouterHistory {
    static historySessionKey = 'history';

    static getHistory() {
        let history = getSessionStorageItem(RouterHistory.historySessionKey);
        if(history === undefined){
            history = [];
            setSessionstorageItem(RouterHistory.historySessionKey, history);
        }
        return history;
    }

    static routeWasPreviousRoute(route) {
        const history = RouterHistory.getHistory();
        try{
            return history[history.length - 2] == route;
        } catch(err){
            return false;
        }
    }

    static getLastRoute() {
        const history = RouterHistory.getHistory();
        try{
            return history[history.length - 1].fullPath;
        } catch(err){
            return undefined;
        }
    }

    static push(route){
        let history = RouterHistory.getHistory();
        history.push(route);
        setSessionstorageItem(RouterHistory.historySessionKey, history);
        return history;
    }

    static popRoute() {
        let history = RouterHistory.getHistory();
        if(history.length === 0) {
            return false;
        }
        history.pop();
        setSessionstorageItem(RouterHistory.historySessionKey, history);
        return history;
    }
}

export const useRouterStore = defineStore('routerStore', () => {
    function pushRoute(route) {
        if(RouterHistory.routeWasPreviousRoute(route)){
            popRoute(route);
        }
        else{
            if(RouterHistory.getLastRoute() !== route.fullPath){
                RouterHistory.push(route);
            }
        }
    }

    function getHistoryTillRoute(route) {
        const cutOffRoutes = [
            '/installations',
            '/dashboard',
            '/login',
            '/aftersale',
            '/support',
            '/register',
            '/monitoring'
        ];
        let routingHistory = [];
        const history = RouterHistory.getHistory();
        for(let i = history.length - 1; i >= 0; --i){
            let currentRoute = history[i];
            if(cutOffRoutes.includes(currentRoute.fullPath)) {
                routingHistory.push(currentRoute);
                break;
            }
            if(currentRoute.fullPath === route){
                routingHistory.push(currentRoute);
                break;
            }
            else{
                if(!routeInHistoryByPath(routingHistory, currentRoute)){
                    routingHistory.push(currentRoute);
                }
            }
        }
        return routingHistory.reverse();
    }

    function routeInHistoryByPath(history, route) {
        for(let historyRoute of history){
            if(historyRoute.fullPath === route.fullPath){
                return true;
            }
        }
        return false;
    }
  
    return { 
        pushRoute,
        getHistoryTillRoute
    }
})