/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

import { 
  hasToken
} from '@helpers/localStorage'
import { updateRouterHistory } from './routerNavGuard'

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { hasSubdomain } from '@/helpers/util'

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL)
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !hasToken()) {
      next({path: '/login'});
  }
  if(!hasSubdomain() && to.path !== '/'){
    next({path: '/'});
  }
  else{
    updateRouterHistory(to, from, next);
  }
})

export default router
