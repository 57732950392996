import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTopNavBarStore = defineStore('topNavBarStore', () => {
    const leftNav = ref('back');
    const navTitle = ref('');
    const rightNav = ref('');
    const downloadDocument = ref({});

    const backFunction = ref(() => {});

    function setLeftNav(leftNavButton){
        leftNav.value = leftNavButton
    }

    function setNavTitle(title){
        navTitle.value = title
    }

    function setRightNav(rightNavButtons){
        rightNav.value = rightNavButtons
    }

    function setBackFunction(backFunction) {
        backFunction.value = backFunction
    }

    function setDownloadDocument(newDownloadDocument) {
        downloadDocument.value = newDownloadDocument;
    }
  
    return { 
        leftNav, setLeftNav, 
        navTitle, setNavTitle, 
        rightNav, setRightNav,
        backFunction, setBackFunction,
        downloadDocument, setDownloadDocument
    }
  })