import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from "axios"
import { getApiRoot } from '@/helpers/api'
import VueCookies from 'vue-cookies'

export const InvoiceStatuses = {
    Paid: 1,
    Open: 0
}

export const useInvoiceStore = defineStore('invoiceStore', () => {
    const invoices = ref([]);
    const invoice = ref();

    async function getInvoiceAndRelatedInvoicesByID(id){
        invoices.value = [];
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.get(
                getApiRoot() + '/sales/invoices/' + id + '/?lang=nl-BE&relatedData=relatedInvoices', 
                { headers }
            )
            if(response.data.errors.length == 0){
                const result = response.data.data[0]
                invoices.value.push({
                    InvoiceID: id,
                    CurrentStatusID: result.PaymentComplete,
                    IsPrepaymentInvoice: false,
                })
                result.relatedInvoices.forEach(relatedInvoice => {
                    invoices.value.push({
                        InvoiceID: relatedInvoice.RelatedInvoice,
                        CurrentStatusID: relatedInvoice.RelatedInvoicePaymentComplete,
                        IsPrepaymentInvoice: relatedInvoice.IsPrepaymentInvoice,
                    })
                })
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw new Error(error);
        }
    }

    async function getInvoiceByID(id){
        try{
            const headers = { Authorization: `${VueCookies.get('token')}` };
            const response = await axios.get(
                getApiRoot() + '/sales/invoices/' + id + '/?lang=nl-BE&relatedData=articles', 
                { headers }
            )
            if(response.data.errors.length == 0){
                invoice.value = response.data.data[0];
            }
            else{
                throw new Error(response.data.errors)
            }
        } catch(error){
            throw new Error(error);
        }
    }
  
    return { 
        invoice,
        invoices,
        getInvoiceAndRelatedInvoicesByID,
        getInvoiceByID
    }
  })