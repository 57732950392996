<template>
    <v-container justify-center fluid>
        <v-row>
            <v-bottom-navigation horizontal elevation="2" class="w-auto" v-if="showForRoutes.includes(route.path)">

                <v-btn size="x-small pa-0" @click="navigateTo('dashboard')">
                    <div v-if="route.path == '/dashboard'">
                        <v-icon color="base0">$dashboard_selected</v-icon>
                        <p class="font-weight-bold text-primary">{{ $t("bottomNav.dashboard") }}</p>
                    </div>
                    <div v-else>
                        <v-icon color="base0">$dashboard</v-icon>
                        <p>{{ $t("bottomNav.dashboard") }}</p>
                    </div>
                </v-btn>

                <v-btn size="x-small pa-0" @click="navigateTo('installations')">
                    <div v-if="route.path == '/installations'">
                        <v-icon >$installations_selected</v-icon>
                        <p class="font-weight-bold text-primary">{{ $t("bottomNav.installations") }}</p>
                    </div>
                    <div v-else>
                        <v-icon >$installations</v-icon>
                        <p>{{ $t("bottomNav.installations") }}</p>
                    </div>
                </v-btn>

                <v-btn size="x-small pa-0" @click="navigateTo('monitoring')">
                    <div v-if="route.path == '/monitoring'">
                        <v-icon >$monitoring_selected</v-icon>
                        <p class="font-weight-bold text-primary">{{ $t("bottomNav.monitoring") }}</p>
                    </div>
                    <div v-else>
                        <v-icon >$monitoring</v-icon>
                        <p>{{ $t("bottomNav.monitoring") }}</p>
                    </div>
                </v-btn>

                <v-btn size="x-small pa-0" @click="navigateTo('aftersale')">
                    <div v-if="route.path == '/aftersale'">
                        <v-icon >$aftersale_selected</v-icon>
                        <p class="font-weight-bold text-primary">{{ $t("bottomNav.aftersale") }}</p>
                    </div>
                    <div v-else>
                        <v-icon >$aftersale</v-icon>
                        <p>{{ $t("bottomNav.aftersale") }}</p>
                    </div>
                </v-btn>

                <v-btn size="x-small pa-0" @click="navigateTo('support')">
                    <div v-if="route.path == '/support'">
                        <v-icon >$ticketing_selected</v-icon>
                        <p class="font-weight-bold text-primary">{{ $t("bottomNav.support") }}</p>
                    </div>
                    <div v-else>
                        <v-icon >$ticketing</v-icon>
                        <p>{{ $t("bottomNav.support") }}</p>
                    </div>
                </v-btn>
            </v-bottom-navigation>
        </v-row>  
    </v-container>
</template>

<script setup>
import router from '@/router'
import { useRoute } from 'vue-router'

const showForRoutes = [
    "/dashboard",
    "/installations",
    "/monitoring",
    "/aftersale",
    "/support" 
]

const route = useRoute();

function navigateTo(path) {
    router.push({ name: `/${path}`})
}

</script>

<style scoped>
p {
    font-size: 0.9em;
    color: rgb(var(--v-theme-base400))
}
</style>