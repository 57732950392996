export function triggerRipple($el) {
    let ev = new Event("mousedown")
    let offset = $el.getBoundingClientRect()
    ev.clientX = offset.left + offset.width/2
    ev.clientY = offset.top + offset.height/2
    $el.dispatchEvent(ev)

    setTimeout(function() {
      $el.dispatchEvent(new Event("mouseup"))
    }, 300)
}

export function roundTo2Decimals(num){
  return +(Math.round(num + "e+2")  + "e-2");
}

//#region Subdomains

export function getSubdomain(){
  // Return a default subdomain on localhost
  if(import.meta.env.VITE_NAME === 'local'){
    return import.meta.env.VITE_SUBDOMAIN;
  }
  let host = window.location.host;
  return host.split('.')[0];
}

export function hasSubdomain(){
  // Using subdomains on localhost doesn't work so just pretend it does
  if(import.meta.env.VITE_NAME === 'local'){
    return true;
  }
  let host = window.location.host;
  let hostParts = host.split(import.meta.env.VITE_DOMAIN);

  return hostParts.length == 2 && hostParts[0] != "";
}

//#endregion